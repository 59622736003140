import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


import { fetchData } from '../utils/apiServices';
import { Header, LoadingSpinner, NotAllowed, ToLink, UrlError } from './_Common';

import '../css/Studies.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const pageSubject = "חומרי לימוד";

const StudiesItem = ({ item, iconColor }) => {
    return (
        <div className="col" role="button">
            <ToLink to={item.link} target="_blank" className="text-decoration-none">
                <div className="d-flex align-items-center p-2 border rounded-4 shadow-lg bg-light hover-shadow parent-div">
                    <div className="me-4 icon-hover-effect-scale" style={{ color: iconColor }}>
                        <FontAwesomeIcon icon="fa-book-open" size="2x" className="icon-hover-effect" aria-label="Class studies" />
                    </div>
                    <div>
                        <span className="item-text text-dark">{item.name}</span>
                    </div>
                </div>
            </ToLink>
        </div>
    );
}
const StudiesFolder = ({ folder, iconColor, handleOnClickFolder }) => {
    return (
        <div className="col" role="button" onClick={() => handleOnClickFolder(folder)}>
            <div className="d-flex align-items-center p-4 border rounded-4 shadow-lg bg-light hover-shadow">
                <div className="me-4" style={{ color: iconColor }}>
                    <FontAwesomeIcon icon="fa-book" size="3x" className="" aria-label="Class studies" />
                </div>
                <div>
                    <p className="h4 mb-0 text-dark fw-semibold">{folder.name}</p>
                </div>
            </div>

        </div>
    );
}
function StudiesMainFolder({ mainFolders, selectedMainFolder, handleOnClickFolder }) {
    return (
        <div className="d-flex text-center border-top border-dark border-4 pt-2 hover-shadow" role="button">
            {mainFolders.map((mainFolder, index) => (
                <span className={`mx-2 ${selectedMainFolder && selectedMainFolder.id === mainFolder.id ? 'selected-MainFolder' : ''}`}
                    onClick={() => handleOnClickFolder(mainFolder)} key={index}>
                    <FontAwesomeIcon icon="fas fa-solid fa-book" className="album-color" />
                    <span className="h5 mx-2">{mainFolder.name}</span>
                </span>
            ))}
        </div>
    );
}

export function StudiesForFolder() {
    const folderId = localStorage.getItem('folderId');

    let { name: folderName } = useParams();
    folderName = decodeURIComponent(folderName);

    const [loading, setLoading] = useState(true);
    const [notAlowed, setNotAlowed] = useState(false);
    const [isUrlError, setIsUrlError] = useState(false);

    const [items, setItems] = useState([]);

    const iconColors = [
        '#FFCDD2', // Light Red
        '#C5CAE9', // Light Blue
        '#F8BBD0', // Light Pink
        '#B3E5FC', // Light Cyan
        '#E1BEE7', // Light Purple
        '#C8E6C9', // Light Green
        '#FFF9C4', // Light Yellow
    ];

    useEffect(() => {
        (async () => {
            const additionalHeaders = [{ name: 'folderName', value: encodeURIComponent(folderName) }];
            const { data: fetchedData, error, message } = await fetchData('/api/StudiesFilesForFolder', null, null, additionalHeaders);
            if (error && error === "Resource not found") {
                if (message && message === "URL Error")
                    setIsUrlError(true);
                else
                    setNotAlowed(true);
            }

            else setItems(fetchedData);

            setLoading(false);
        })();
    }, []);

    if (loading) { return <LoadingSpinner />; }
    if (notAlowed) { return <NotAllowed />; }
    if (isUrlError) { return <UrlError />; }
    const header = `${pageSubject} - ${folderName && folderName}`;
    const NotFound = `לא נמצאו ${pageSubject} עבור - ${folderName}`;
    return (
        <div className="py-3 w-md-75 mx-auto">
            <Header header={header} />
            <div className="row row-cols-1 row-cols-md-3 g-4 py-3">
                {items && items.map((item, index) => (
                    <StudiesItem key={index} item={item} iconColor={iconColors[index % iconColors.length]} />
                ))}
                {items && items.length === 0 && <div className="h5">{NotFound}</div>}
            </div>
        </div>
    );
}
export default function Studies() {
    const [loading, setLoading] = useState(true);
    const [notAlowed, setNotAlowed] = useState(false);

    const [mainFolders, setMainFolders] = useState([]);
    const [selectedMainFolder, setSelectedMainFolder] = useState(null);

    const [folders, setFolders] = useState([]);

    useEffect(() => {
        (async () => {
            if (!selectedMainFolder) {
                const { data: fetchedDataFolder } = await fetchData('/api/Studies');
                setMainFolders(fetchedDataFolder);
                if (fetchedDataFolder) {
                    const defaultFolder = fetchedDataFolder.find(folder => folder.isDefault);
                    if (defaultFolder) setSelectedMainFolder(defaultFolder);
                }
            }
            if (selectedMainFolder) {
                const additionalHeaders = selectedMainFolder && [{ name: 'StudiesId', value: selectedMainFolder.id }];
                const { data: fetchedData, error, message } = await fetchData('/api/StudiesFolders', null, null, additionalHeaders);
                if (error && error === "Resource not found") setNotAlowed(true);
                else setFolders(fetchedData);
            }

            setLoading(false);
        })();
    }, [selectedMainFolder]);

    const handleOnClickFolder = (folder) => {
        //localStorage.setItem('folderId', folder.link);
        const folderUrl = `/Studies/${encodeURIComponent(folder.name)}`;
        window.open(folderUrl, '_self');
    };
    if (loading || !folders || folders.length === 0) { return <LoadingSpinner />; }
    if (notAlowed) { return <NotAllowed />; }
    const header = `${selectedMainFolder && selectedMainFolder.name}`;
    return (
        <div className="py-3 w-md-75 mx-auto">
            <Header header={header} />
            <div className="row row-cols-1 row-cols-md-3 g-4 py-3">
                {folders && folders.map((folder, index) => (
                    <StudiesFolder key={index} folder={folder} handleOnClickFolder={handleOnClickFolder} />
                ))}
            </div>
            <StudiesMainFolder mainFolders={mainFolders} selectedMainFolder={selectedMainFolder} handleOnClickFolder={setSelectedMainFolder} />
        </div>
    );
}