import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import '../css/Courses.css';
// import '../css/Course.css';

import { fetchData, getHomePageUrl, getPageHeader } from '../utils/apiServices';
import { toPageTitle } from '../utils/utilityFunctions';
import { Header, ItemIcon, LoadingSpinner, NotAllowed, ToLink } from './_Common';


const CourseDetails = ({ id, courseItems, homePageUrl }) => {
    const courseItem = courseItems.find(courseItem => courseItem.id == id);

    document.title = toPageTitle(courseItem.header);

    return (
        courseItem && homePageUrl &&
        <div className="pb-5">
            <h2 className="text-center pt-3">
                {<ItemIcon itemIcon={courseItem.itemIcon} homePageUrl={homePageUrl} />}
                <span className="me-2 ms-0">
                    {courseItem.header}
                </span>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: courseItem.text }} />
        </div>)
};

const CourseItem = ({ courseItem, bgColor, homePageUrl }) => {
    return (
        courseItem && homePageUrl &&
        <li className="col-md-4 mb-4">
            <ToLink to={`/${"Courses"}/${courseItem.id}`} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                <div
                    className="media bg-white shadow-sm p-4 rounded-3 position-relative overflow-hidden list-item-hover"
                    style={{ borderLeft: `4px solid ${bgColor}` }}
                >
                    <div className="d-flex align-items-center">
                        <div className="icon text-dark">
                            <ItemIcon itemIcon={courseItem.itemIcon} homePageUrl={homePageUrl} />
                        </div>
                        <div className="media-body ms-3">
                            <h5 className="mt-0 mb-2 text-dark">{courseItem.header}</h5>
                        </div>
                    </div>
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100 overlay-effect"
                        style={{ backgroundColor: bgColor, opacity: 0.1, zIndex: -1 }}                >
                    </div>
                </div>
            </ToLink>
        </li>
    );
};


const CourseItems = ({ courseItems, homePageUrl }) => {
    const backgroundColors = [
        '#FFCDD2', // Light Red
        '#C5CAE9', // Light Blue
        '#F8BBD0', // Light Pink
        '#B3E5FC', // Light Cyan
        '#E1BEE7', // Light Purple
        '#C8E6C9', // Light Green
        '#FFF9C4', // Light Yellow
    ];

    return (
        <ul className="list-unstyled row mt-3">
            {courseItems.map((courseItem, index) => (
                <CourseItem courseItem={courseItem} bgColor={backgroundColors[index % backgroundColors.length]} homePageUrl={homePageUrl} key={index} />
            ))}
        </ul>
    )
};

export default function CoursePage() {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [notAlowed, setNotAlowed] = useState(false);

    const [homePageUrl, setHomePageUrl] = useState(null);
    const [pageHeader, setPageHeader] = useState(null);

    const [courseItems, setCourseItems] = useState([]);

    useEffect(() => {
        (async () => {
            const { data: fetchedData, error } = await fetchData('/api/Courses');
            if (error && error === "Resource not found") setNotAlowed(true);
            else setCourseItems(fetchedData);

            const fetchedUrl = await getHomePageUrl();
            setHomePageUrl(fetchedUrl);

            const fetchedPageHeader = await getPageHeader({ pageName: "Courses" });
            setPageHeader(fetchedPageHeader);

            setLoading(false);
        })();
    }, []);

    if (loading) { return <LoadingSpinner />; }
    if (notAlowed) { return <NotAllowed />; }

    const header = pageHeader;
    //console.log(courseItems);
    return (
        <div className="py-3 w-md-75 mx-auto">
            <Header header={header} />
            {!id && courseItems.length > 0 && <CourseItems courseItems={courseItems} homePageUrl={homePageUrl} />}
            {id && courseItems.length > 0 && <CourseDetails id={id} courseItems={courseItems} homePageUrl={homePageUrl} />}
        </div>
    );
}